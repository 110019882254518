<template>
	<div class="out-back-demo" v-loading="loading">
		<div v-if="isEmpty == false" v-for="(item, index) in PurchaseList" :key="index">
			<Purchase :PurchaseList="item" type1="loking"></Purchase>
		</div>
		<div v-if="isEmpty == true" class="emptyView">
			<el-empty :image-size="100"></el-empty>
		</div>
	</div>
</template>

<script>
	import Purchase from "@/components/content/Purchase.vue"
	import {
		myLockMusic
	} from "@/api/Mine"
	export default {
		props: {},
		data() {
			return {
				loading: true,
				isEmpty: false,
				PurchaseList: [],
				// PurchaseList: [{
				// 		title: '英雄联盟-黑暗时刻',
				// 		author: 'Maud Hampton',
				// 		type: '授权类型：作曲',
				// 		area: '授权地区：大陆',
				// 		typeMore: '授权类型：灌录权 （灌录权、传播权、复制权）',
				// 		code: '订单编号：4524341345434',
				// 		price: '￥30',
				// 		img: 'https://gimg3.baidu.com/yule/src=http%3A%2F%2Fgips2.baidu.com%2Fit%2Fu%3D1715359177%2C946060353%26fm%3D3007%26app%3D3007%26f%3DJPEG%3Fw%3D480%26h%3D480&refer=http%3A%2F%2Fwww.baidu.com&app=2019&size=w931&n=0&g=0n&q=75&fmt=auto?sec=1667667600&t=98df8a00e53f012ac94086e90e7e9860',
				// 	},
				// 	{
				// 		title: '点燃我，温暖你',
				// 		author: '曾可妮',
				// 		type: '授权类型：作曲',
				// 		area: '授权地区：大陆',
				// 		typeMore: '授权类型：灌录权 （灌录权、传播权、复制权）',
				// 		code: '订单编号：4524341345434',
				// 		price: '￥30',
				// 		img: 'https://gimg3.baidu.com/yule/src=http%3A%2F%2Fgips2.baidu.com%2Fit%2Fu%3D1715359177%2C946060353%26fm%3D3007%26app%3D3007%26f%3DJPEG%3Fw%3D480%26h%3D480&refer=http%3A%2F%2Fwww.baidu.com&app=2019&size=w931&n=0&g=0n&q=75&fmt=auto?sec=1667667600&t=98df8a00e53f012ac94086e90e7e9860',
				// 	}
				// ]

			}
		},
		components: {
			Purchase
		},
		computed: {},
		created() {},
		mounted() {
			this.$store.state.purchaseType = 2
			this.myLockMusic()
		},
		watch: {
			"$store.state.myPurchaseStatus": {
			  handler(newValue, oldValue) {
			    console.log("myPurchaseStatus", newValue);
				this.myLockMusic()
			  },
			},
		},
		methods: {
			myLockMusic(){
				// 已锁定的歌曲
				myLockMusic({
					status: this.$store.state.myPurchaseStatus
				}).then((res) => {
						console.log(res, '已锁定的歌曲');
						this.PurchaseList = res.data
						this.loading = false
						if(this.PurchaseList == ''){
							this.isEmpty = true
						}else {
							this.isEmpty = false
						}
						// localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					})
					.catch((err) => {
						// console.log(err, '我的信息失败');
					});
			}
		}
	}
</script>

<style scoped lang='less'>
	/deep/ .el-loading-mask{
		background-color: rgba(0,0,0,0);
	}
	.out-back-demo {
		width: 100%;
		height: 850px;
		.emptyView{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 30vh;
		}
	}
</style>
